import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useDate } from "../../hooks/useDate"
import { useSelect } from "../../hooks/useSelect"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Calendar from "../../assets/calendar.svg"
import Cabin from "../../assets/house.svg"
import People from "../../assets/group.svg"
import { device } from "../../utils/mediaQueries"
import placeSuggestions from "../../data/placeSuggestionsv2.json"
import { BsHouseFill, BsCalendarFill, BsPeopleFill } from "react-icons/bs"
import "./theme.css"
import { Icon, Input, Select, useDisclosure } from "@chakra-ui/react"
import moment from "moment"

import fi from "date-fns/locale/fi"
import Autosuggest from "react-autosuggest"
import { ResultContainer } from "../ResultComponents/ResultContainer"
registerLocale("fi", fi)

console.log("placeSuggestions", placeSuggestions)

const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length

  return inputLength === 0
    ? []
    : placeSuggestions.filter(
        place => place.title.toLowerCase().slice(0, inputLength) === inputValue
      )
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.title

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.title[0].toUpperCase()}
    {suggestion.title.slice(1)}
  </div>
)

const DatePick = styled(DatePicker)`
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;

  border: 1px solid #dcdcdc;
  border-radius: 4px;
  :active {
    border: none;
  }
  :focus {
    border: 1px solid lightblue;
  }
`
const PersonSelect = styled.select`
  margin: 0px;
  flex: 1;
  padding: 10px 40px;
  font-size: 16px;
  font-weight: 300;
  background: white;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  height: 52px;
  outline: none;
  padding-left: 15px;

  :active {
    border: none;
    outline: none;
  }
  :focus {
    border: 1px solid lightblue;
    outline: none;
  }
`

const ButtonText = styled.div`
  font-size: 22px;
  padding: 0.5rem;
`
const SearchButton = styled.a`
  min-width: 140px;
  height: 50px;
  flex: 1;
  text-align: center;
  background-color: #f52626;
  color: white;
  font-weight: 700;
  max-width: 200px;
  margin: 1rem auto;
  margin-top: 1.5rem;
  justify-content: center;
  border-radius: 6px;
  align-items: center;
  align-content: center;
  border: 2px solid white;

  -webkit-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  -ms-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  :hover {
    background-color: #c52323;
  }
`

const PersonOption = styled.option`
  padding: 10px 40px;
  margin: 0px;
`

const Container = styled.div`
  border-radius: 15px;
  box-shadow: -2px 1px 13px 6px rgba(0, 0, 0, 0.5);
  background-color: white;
  padding: 1rem;
  max-width: 1200px;
  margin: 0px auto;
`

const SearchBackground = styled.div``

const HeaderSearch = styled.h3`
  color: #3c3c3c;
  padding-bottom: 1rem;
  margin-top: 1rem;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 24px;
  font-weight: 100;
`

const BarContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(50px, 1fr));
  margin: 0px;
  padding: 0px;
  justify-content: center;
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.row ? "row" : "column")};
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0rem auto;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
`

const DatePickerComponent = props => {
  console.log(props.name)

  const placeData = placeSuggestions.filter(
    item => item.title.toLowerCase() === props.name.toLowerCase()
  )[0] ?? {
    seoName: "/etala-pohjanmaa",
    extraKeyword: null,
    title: "Etelä-Pohjanmaa",
    description: null,
    imageLink: null,
    link: null,
    id_str: "municipality_346",
    locationType: "place",
    name: null,
  }

  console.log("PLACEDATA", placeData)
  const [selectedPlace, setSelectedPlace] = useState(
    props.name.toLowerCase() === "suomi"
      ? ""
      : props.name[0].toUpperCase() + props.name.slice(1)
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [lomarengasId, setLomarengasId] = useState(
    placeData && placeData.seoName ? placeData.seoName : ""
  )
  // const [lomarengasId, setLomarengasId] = useState(
  //   props.urlFix || placeData.value
  // )

  console.log("LOMARENGASID", lomarengasId)
  const [bookingId, setBookingId] = useState(props.bookingId ?? null)
  const [isRegion, setIsRegion] = useState(props.isRegion ?? false)
  const [suggestions, setSuggestions] = useState([])
  const [path, setPath] = useState("")
  console.log("PATH", path)
  const arriveDayHook = useDate()
  const leaveDayHook = useDate()
  const selectHook = useSelect()
  const handleChangeDate = date => {
    arriveDayHook.changeDate(date)
  }

  const trackClick = (label, category) => {
    typeof window !== "undefined" &&
      window.gtag("event", `CompareClick`, {
        event_label: lomarengasId,
        event_category: category,
      })
  }

  // const filterFix = filter ? type[filter] : type[lomarengasId.split("_")[0]]

  // useEffect(() => {
  //   if (
  //     arriveDayHook.date &&
  //     leaveDayHook.date &&
  //     selectHook.select &&
  //     keyWordFix
  //   ) {
  //     trackClick(keyWordFix, "AllFilters")
  //     onOpen()
  //   }
  // }, [arriveDayHook, leaveDayHook, selectHook, keyWordFix])

  return (
    <SearchBackground>
      <Container>
        <HeaderSearch>
          Etsi vuokramökki majoitus kohteessa {props.name[0].toUpperCase()}
          {props.name.slice(1)}
        </HeaderSearch>
        <BarContainer>
          <InputContainer row>
            <DateContainer>
              <Icon as={BsHouseFill} marginRight="1rem" />
              <Autosuggest
                inputProps={{
                  placeholder: "Minne olet menossa?",
                  autoComplete: "abcd",
                  name: "place",
                  id: "place",
                  value: selectedPlace,
                  onChange: (_event, { newValue }) => {
                    setSelectedPlace(newValue)
                  },
                }}
                suggestions={suggestions}
                onSuggestionsFetchRequested={({ value }) => {
                  if (!value) {
                    setSuggestions([])
                    return
                  }
                  setSuggestions(getSuggestions(value))
                }}
                onSuggestionsClearRequested={() => {
                  setSuggestions([])
                }}
                onSuggestionSelected={(event, { suggestion, method }) => {
                  if ((method = "enter")) {
                    event.preventDefault()
                  }
                  setSelectedPlace(
                    suggestion.title[0].toUpperCase() +
                      suggestion.title.slice(1)
                  )
                  setPath(suggestion.seoName)
                  setLomarengasId(
                    placeData && placeData.seoName ? placeData.seoName : ""
                  )

                  setIsRegion(suggestion.region)
                }}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
              />
            </DateContainer>
            <DateContainer>
              <Icon as={BsCalendarFill} marginRight="1rem" />
              <Input
                as={DatePicker}
                locale="fi"
                placeholderText="Tulopäivä"
                selected={arriveDayHook.date}
                onChange={date => handleChangeDate(date)}
                selectsStart
                minDate={new Date()}
                startDate={arriveDayHook.date}
              />
            </DateContainer>
            <DateContainer>
              <Icon as={BsCalendarFill} marginRight="1rem" />

              <Input
                as={DatePicker}
                locale="fi"
                selected={leaveDayHook.date}
                onChange={date => leaveDayHook.changeDate(date)}
                placeholderText="Lähtöpäivä"
                selectsEnd
                startDate={arriveDayHook.date}
                endDate={leaveDayHook.date}
                minDate={arriveDayHook.date}
              />
            </DateContainer>
            <DateContainer>
              <Icon as={BsPeopleFill} marginRight="1rem" />

              <Select
                onChange={selectHook.handleSelect}
                defaultValue={selectHook.select ?? "10"}
              >
                <PersonOption value="10" disabled>
                  Montako henkilöä?
                </PersonOption>
                <PersonOption value="1">1 henkilö</PersonOption>
                <PersonOption value="2">2 henkilöä</PersonOption>
                <PersonOption value="3">3 henkilöä</PersonOption>
                <PersonOption value="4">4 henkilöä</PersonOption>
                <PersonOption value="5">5 henkilöä</PersonOption>
                <PersonOption value="6">6 henkilöä</PersonOption>
                <PersonOption value="7">Yli 6 henkilöä</PersonOption>
              </Select>
            </DateContainer>
          </InputContainer>
          <InputContainer>
            <SearchButton
              onClick={trackClick}
              href={`https://on.lomarengas.fi/t/t?a=1071150667&as=1475346119&t=2&tk=lomarengasId&url=https://www.lomarengas.fi/mokkihaku${
                lomarengasId
                  ? lomarengasId.includes("/")
                    ? lomarengasId
                    : `/${lomarengasId}`
                  : path.includes("/")
                  ? path
                  : `/${path}`
              }?startDate=${moment(arriveDayHook.date).format(
                "DD[.]MM[.]YYYY"
              )}&endDate=${moment(leaveDayHook.date).format(
                "DD[.]MM[.]YYYY"
              )}&persons=${selectHook.select ?? "1"}`}
            >
              <ButtonText>Hae!</ButtonText>
            </SearchButton>

            {/* <LomaRengasText>
              Vertailun tarjoaa{" "}
              <a target="_blank noopener" href="/lomarengas">
                Lomarengas.fi
              </a>
            </LomaRengasText> */}
          </InputContainer>
        </BarContainer>
      </Container>
    </SearchBackground>
  )
}

export default DatePickerComponent
