import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import CityImage from "../images/CityImage"

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  grid-gap: 1rem;
  justify-content: center;
  margin: 1rem 0rem;
`
const CityImageContainer = styled.div`
  position: relative;
  text-align: center;
  z-index: -1;
`

const Title = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 33px;
  font-weight: 700;
  line-height: 2rem;
  padding: 1rem 2rem;
  border-radius: 15px;
`

export const CityCategories = props => {
  const cities = props.cities
  const replaceA = new RegExp("\\ä", "g") // Throws SyntaxError
  const replaceO = new RegExp("\\ö", "g") // Throws SyntaxError
  const replaceDash = new RegExp("\\-", "g") // Throws SyntaxError

  return (
    <Container>
      {props.bulk
        ? cities.map((city, i) => (
            <Link
              key={i}
              to={
                city.node.parentCity
                  ? `/${city.node.region}/${city.node.parentCity}/${city.node.city}`
                      .replace(replaceA, "a")
                      .replace(replaceO, "o")
                  : `/${city.node.region}/${city.node.city}`
                      .replace(replaceA, "a")
                      .replace(replaceO, "o")
              }
            >
              <CityImageContainer>
                <CityImage
                  imgName={
                    city.node.region
                      ? `${city.node.region
                          .replace(replaceA, "a")
                          .replace(replaceO, "o")
                          .replace(replaceDash, "")}.jpg`
                      : `${city.node.city}.jpg`
                  }
                />
                <Title>{`${city.node.city[0].toUpperCase()}${city.node.city.slice(
                  1
                )}`}</Title>
              </CityImageContainer>
            </Link>
          ))
        : cities.map((city, i) => (
            <Link key={i} to={city.node.frontmatter.path}>
              <CityImageContainer>
                <CityImage imgName={city.node.frontmatter.img} />
                <Title>{city.node.frontmatter.name}</Title>
              </CityImageContainer>
            </Link>
          ))}
    </Container>
  )
}
