import { RepeatIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Text,
} from "@chakra-ui/react"
import moment from "moment"
import React from "react"
import styled from "styled-components"
import CompanyImage from "../images/CompanyImage"

const Container = styled.div`
  background-color: "white";
  padding: 1rem;
`

const Result = styled.div``

export const ResultContainer = props => {
  const {
    arriveDate,
    leavingDate,
    persons,
    region,
    placeName,
    lomarengasId,
    // lomarengasLocationType,
    bookingId,
    isOpen,
    resetFilters,
    onClose,
  } = props

  const trackClick = (action, label, category) => {
    typeof window !== "undefined" &&
      // @ts-expect-error
      window.gtag("event", action, {
        event_label: label,
        event_category: category,
      })
  }

  const correctBookingId = !bookingId
    ? bookingId
    : region
    ? bookingId
    : bookingId[0] === "9"
    ? bookingId
    : -`${bookingId}`
  const link = {
    trivago: "https://www.trivago.fi/",
    booking: !bookingId
      ? `https://www.booking.com/country/fi.fi.html?aid=2097613&checkin_monthday=${new Date(
          arriveDate
        )?.getDate()}&checkin_month=${
          arriveDate ? new Date(arriveDate)?.getMonth() + 1 : 0
        }&checkin_year=${new Date(
          arriveDate
        )?.getFullYear()}&checkout_monthday=${new Date(
          leavingDate
        )?.getDate()}&checkout_month=${
          leavingDate ? new Date(leavingDate)?.getMonth() + 1 : 0
        }&checkout_year=${new Date(
          leavingDate
        )?.getFullYear()}&nflt=ht_id%253D206%253Bht_id%253D218%253Bht_id%253D201%253Bht_id%253D220%253Bht_id%253D221%253Bht_id%253D216%253Bht_id%253D223&no_rooms=1&group_adults=${persons}&room1&label=etusivu-tyhja`
      : `https://www.booking.com/searchresults.fi.html?${
          region ? "region" : "city"
        }=${correctBookingId}&nflt=ht_id%253D206%253Bht_id%253D218%253Bht_id%253D201%253Bht_id%253D220%253Bht_id%253D221%253Bht_id%253D216%253Bht_id%253D223&aid=1621498&checkin_monthday=${new Date(
          arriveDate
        )?.getDate()}&checkin_month=${
          arriveDate ? new Date(arriveDate)?.getMonth() + 1 : 0
        }&checkin_year=${new Date(
          arriveDate
        )?.getFullYear()}&checkout_monthday=${new Date(
          leavingDate
        )?.getDate()}&checkout_month=${
          leavingDate ? new Date(leavingDate)?.getMonth() + 1 : 0
        }&checkout_year=${new Date(
          leavingDate
        )?.getFullYear()}&no_rooms=1&group_adults=${persons}&room1=A&label=kaupunki${placeName}-vuokramokit`,
    lomarengas: `https://www.lomarengas.fi/mokkihaku/${lomarengasId}?startDate=${moment(
      arriveDate
    ).format("DD[.]MM[.]YYYY")}&endDate=${moment(leavingDate).format(
      "DD[.]MM[.]YYYY"
    )}&persons=${persons}`,
  }

  const handleReset = () => {
    resetFilters()
    onClose()
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="top"
      onClose={onClose}
      motionPreset="slideInBottom"
      size="full"
    >
      <DrawerOverlay>
        <DrawerContent background="#f0f0f0">
          <DrawerCloseButton onClick={handleReset} />
          <DrawerBody paddingX="0.1rem">
            <Heading textAlign="center">Hakutulokset:</Heading>

            {arriveDate && leavingDate && (
              <Text textAlign="center" marginY="1rem">{`${moment(
                arriveDate
              ).format("DD[.]MM[.]YYYY")} - ${moment(leavingDate).format(
                "DD[.]MM[.]YYYY"
              )} kohteessa ${placeName} ${persons} henkilöä`}</Text>
            )}
            <Heading textAlign="center" fontSize="1.1rem" fontWeight="400">
              Mökkejä vapaana seuraavissa palveluissa:
            </Heading>
            {bookingId && (
              <a
                href={link.booking}
                target="_blank"
                onClick={() =>
                  trackClick("Booking-Card-Click", placeName, "CardClick")
                }
              >
                <Box
                  display="flex"
                  minH="80px"
                  boxShadow="sm"
                  m={["1rem 0rem", "1rem auto"]}
                  justifyContent="space-between"
                  alignItems="center"
                  background="white"
                  borderRadius="5px"
                  paddingX={["1rem", "1rem"]}
                  maxW="600px"
                  flexWrap="wrap"
                >
                  <CompanyImage imgName="booking_logo.png" />
                  <Button color="blue.500" background="transparent" size="sm">
                    Näytä vapaat mökit {">>"}
                  </Button>
                </Box>
              </a>
            )}
            <a
              href={link.lomarengas}
              target="_blank"
              onClick={() =>
                trackClick("Lomarengas-Card-Click", placeName, "CardClick")
              }
            >
              <Box
                display="flex"
                minH="80px"
                boxShadow="sm"
                m={["0rem auto", "1rem auto"]}
                justifyContent="space-between"
                alignItems="center"
                background="white"
                borderRadius="5px"
                paddingX={["1rem"]}
                maxW="600px"
              >
                <CompanyImage imgName="lomarengas_logo.png" />
                <Button color="blue.500" background="transparent" size="sm">
                  Näytä vapaat mökit {">>"}
                </Button>
              </Box>
            </a>
            <Box margin="2rem auto" display="flex" justifyContent="center">
              <Button onClick={handleReset}>
                <RepeatIcon />
                <Text marginLeft="1rem">Uusi haku</Text>
              </Button>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
